export const ActiveBooking = () => import('../../components/ActiveBooking.vue' /* webpackChunkName: "components/active-booking" */).then(c => wrapFunctional(c.default || c))
export const Btn = () => import('../../components/Btn.vue' /* webpackChunkName: "components/btn" */).then(c => wrapFunctional(c.default || c))
export const CountrySelector = () => import('../../components/CountrySelector.vue' /* webpackChunkName: "components/country-selector" */).then(c => wrapFunctional(c.default || c))
export const Modal = () => import('../../components/Modal.vue' /* webpackChunkName: "components/modal" */).then(c => wrapFunctional(c.default || c))
export const ToastAlert = () => import('../../components/ToastAlert.vue' /* webpackChunkName: "components/toast-alert" */).then(c => wrapFunctional(c.default || c))
export const AccountUserNavigation = () => import('../../components/account/UserNavigation.vue' /* webpackChunkName: "components/account-user-navigation" */).then(c => wrapFunctional(c.default || c))
export const AccountUserNavigationMobile = () => import('../../components/account/UserNavigationMobile.vue' /* webpackChunkName: "components/account-user-navigation-mobile" */).then(c => wrapFunctional(c.default || c))
export const BookingConfirmedBooking = () => import('../../components/booking/ConfirmedBooking.vue' /* webpackChunkName: "components/booking-confirmed-booking" */).then(c => wrapFunctional(c.default || c))
export const BookingMainSummary = () => import('../../components/booking/MainSummary.vue' /* webpackChunkName: "components/booking-main-summary" */).then(c => wrapFunctional(c.default || c))
export const BookingOptionsAndExtras = () => import('../../components/booking/OptionsAndExtras.vue' /* webpackChunkName: "components/booking-options-and-extras" */).then(c => wrapFunctional(c.default || c))
export const BookingPassengerDetails = () => import('../../components/booking/PassengerDetails.vue' /* webpackChunkName: "components/booking-passenger-details" */).then(c => wrapFunctional(c.default || c))
export const BookingPaymentDetails = () => import('../../components/booking/PaymentDetails.vue' /* webpackChunkName: "components/booking-payment-details" */).then(c => wrapFunctional(c.default || c))
export const BookingSteps = () => import('../../components/booking/Steps.vue' /* webpackChunkName: "components/booking-steps" */).then(c => wrapFunctional(c.default || c))
export const AccountBookingItineraryTab = () => import('../../components/account/booking/ItineraryTab.vue' /* webpackChunkName: "components/account-booking-itinerary-tab" */).then(c => wrapFunctional(c.default || c))
export const AccountBookingOptionsTab = () => import('../../components/account/booking/OptionsTab.vue' /* webpackChunkName: "components/account-booking-options-tab" */).then(c => wrapFunctional(c.default || c))
export const AccountBookingPassengersTab = () => import('../../components/account/booking/PassengersTab.vue' /* webpackChunkName: "components/account-booking-passengers-tab" */).then(c => wrapFunctional(c.default || c))
export const AccountBookingPaymentsTab = () => import('../../components/account/booking/PaymentsTab.vue' /* webpackChunkName: "components/account-booking-payments-tab" */).then(c => wrapFunctional(c.default || c))
export const AccountBookingSummaryTab = () => import('../../components/account/booking/SummaryTab.vue' /* webpackChunkName: "components/account-booking-summary-tab" */).then(c => wrapFunctional(c.default || c))
export const AccountBookingTabs = () => import('../../components/account/booking/Tabs.vue' /* webpackChunkName: "components/account-booking-tabs" */).then(c => wrapFunctional(c.default || c))
export const AccountDashboardMyBookings = () => import('../../components/account/dashboard/MyBookings.vue' /* webpackChunkName: "components/account-dashboard-my-bookings" */).then(c => wrapFunctional(c.default || c))
export const AccountDashboardPartnersOffers = () => import('../../components/account/dashboard/PartnersOffers.vue' /* webpackChunkName: "components/account-dashboard-partners-offers" */).then(c => wrapFunctional(c.default || c))
export const AccountElementsBookingCard = () => import('../../components/account/elements/BookingCard.vue' /* webpackChunkName: "components/account-elements-booking-card" */).then(c => wrapFunctional(c.default || c))
export const AccountElementsFeatureBox = () => import('../../components/account/elements/FeatureBox.vue' /* webpackChunkName: "components/account-elements-feature-box" */).then(c => wrapFunctional(c.default || c))
export const AccountElementsFeatureButton = () => import('../../components/account/elements/FeatureButton.vue' /* webpackChunkName: "components/account-elements-feature-button" */).then(c => wrapFunctional(c.default || c))
export const AccountElementsSummaryItem = () => import('../../components/account/elements/SummaryItem.vue' /* webpackChunkName: "components/account-elements-summary-item" */).then(c => wrapFunctional(c.default || c))
export const AccountProfileMyDetails = () => import('../../components/account/profile/MyDetails.vue' /* webpackChunkName: "components/account-profile-my-details" */).then(c => wrapFunctional(c.default || c))
export const BookingElementsApplyDiscount = () => import('../../components/booking/elements/ApplyDiscount.vue' /* webpackChunkName: "components/booking-elements-apply-discount" */).then(c => wrapFunctional(c.default || c))
export const BookingElementsBillingAddress = () => import('../../components/booking/elements/BillingAddress.vue' /* webpackChunkName: "components/booking-elements-billing-address" */).then(c => wrapFunctional(c.default || c))
export const BookingSummary = () => import('../../components/booking/elements/BookingSummary.vue' /* webpackChunkName: "components/booking-summary" */).then(c => wrapFunctional(c.default || c))
export const BookingElementsDiscountSummary = () => import('../../components/booking/elements/DiscountSummary.vue' /* webpackChunkName: "components/booking-elements-discount-summary" */).then(c => wrapFunctional(c.default || c))
export const BookingElementsPassenger = () => import('../../components/booking/elements/Passenger.vue' /* webpackChunkName: "components/booking-elements-passenger" */).then(c => wrapFunctional(c.default || c))
export const BookingElementsPaymentOptions = () => import('../../components/booking/elements/PaymentOptions.vue' /* webpackChunkName: "components/booking-elements-payment-options" */).then(c => wrapFunctional(c.default || c))
export const BookingElementsQuoteTool = () => import('../../components/booking/elements/QuoteTool.vue' /* webpackChunkName: "components/booking-elements-quote-tool" */).then(c => wrapFunctional(c.default || c))
export const BookingElementsRelatedProduct = () => import('../../components/booking/elements/RelatedProduct.vue' /* webpackChunkName: "components/booking-elements-related-product" */).then(c => wrapFunctional(c.default || c))
export const BookingElementsStepNavigation = () => import('../../components/booking/elements/StepNavigation.vue' /* webpackChunkName: "components/booking-elements-step-navigation" */).then(c => wrapFunctional(c.default || c))
export const BookingPaymentsAnalyticsPurchaseEvent = () => import('../../components/booking/payments/AnalyticsPurchaseEvent.vue' /* webpackChunkName: "components/booking-payments-analytics-purchase-event" */).then(c => wrapFunctional(c.default || c))
export const BookingStatus = () => import('../../components/booking/payments/BookingStatus.vue' /* webpackChunkName: "components/booking-status" */).then(c => wrapFunctional(c.default || c))
export const BookingPaymentsDiscounts = () => import('../../components/booking/payments/Discounts.vue' /* webpackChunkName: "components/booking-payments-discounts" */).then(c => wrapFunctional(c.default || c))
export const BookingPaymentsProcessPayment = () => import('../../components/booking/payments/ProcessPayment.vue' /* webpackChunkName: "components/booking-payments-process-payment" */).then(c => wrapFunctional(c.default || c))
export const BookingPaymentsShuttleMessage = () => import('../../components/booking/payments/ShuttleMessage.vue' /* webpackChunkName: "components/booking-payments-shuttle-message" */).then(c => wrapFunctional(c.default || c))
export const BookingPaymentsTransaction = () => import('../../components/booking/payments/Transaction.vue' /* webpackChunkName: "components/booking-payments-transaction" */).then(c => wrapFunctional(c.default || c))
export const AccountSummary = () => import('../../components/account/booking/elements/AccountSummary.vue' /* webpackChunkName: "components/account-summary" */).then(c => wrapFunctional(c.default || c))
export const AccountBookingElementsBillingAddress = () => import('../../components/account/booking/elements/BillingAddress.vue' /* webpackChunkName: "components/account-booking-elements-billing-address" */).then(c => wrapFunctional(c.default || c))
export const AccountBookingElementsPassengerAccordion = () => import('../../components/account/booking/elements/PassengerAccordion.vue' /* webpackChunkName: "components/account-booking-elements-passenger-accordion" */).then(c => wrapFunctional(c.default || c))
export const AccountBookingElementsPassengerElm = () => import('../../components/account/booking/elements/PassengerElm.vue' /* webpackChunkName: "components/account-booking-elements-passenger-elm" */).then(c => wrapFunctional(c.default || c))
export const AccountBookingElementsPassengerNote = () => import('../../components/account/booking/elements/PassengerNote.vue' /* webpackChunkName: "components/account-booking-elements-passenger-note" */).then(c => wrapFunctional(c.default || c))
export const AccountBookingElementsPaymentOptions = () => import('../../components/account/booking/elements/PaymentOptions.vue' /* webpackChunkName: "components/account-booking-elements-payment-options" */).then(c => wrapFunctional(c.default || c))
export const AccountBookingElementsTransactionSummary = () => import('../../components/account/booking/elements/TransactionSummary.vue' /* webpackChunkName: "components/account-booking-elements-transaction-summary" */).then(c => wrapFunctional(c.default || c))
export const AccountBookingPaymentsProcessPayment = () => import('../../components/account/booking/payments/ProcessPayment.vue' /* webpackChunkName: "components/account-booking-payments-process-payment" */).then(c => wrapFunctional(c.default || c))
export const AccountBookingPaymentsShuttleMessage = () => import('../../components/account/booking/payments/ShuttleMessage.vue' /* webpackChunkName: "components/account-booking-payments-shuttle-message" */).then(c => wrapFunctional(c.default || c))
export const AccountBookingPaymentsTransaction = () => import('../../components/account/booking/payments/Transaction.vue' /* webpackChunkName: "components/account-booking-payments-transaction" */).then(c => wrapFunctional(c.default || c))
export const BookingElementsPlaceholderFeatureBoxLoading = () => import('../../components/booking/elements/placeholder/FeatureBoxLoading.vue' /* webpackChunkName: "components/booking-elements-placeholder-feature-box-loading" */).then(c => wrapFunctional(c.default || c))
export const BookingElementsPlaceholderIntroTextLoading = () => import('../../components/booking/elements/placeholder/IntroTextLoading.vue' /* webpackChunkName: "components/booking-elements-placeholder-intro-text-loading" */).then(c => wrapFunctional(c.default || c))
export const BookingElementsPlaceholderPassengerLoading = () => import('../../components/booking/elements/placeholder/PassengerLoading.vue' /* webpackChunkName: "components/booking-elements-placeholder-passenger-loading" */).then(c => wrapFunctional(c.default || c))
export const BookingElementsRelatedActivityOption = () => import('../../components/booking/elements/related/ActivityOption.vue' /* webpackChunkName: "components/booking-elements-related-activity-option" */).then(c => wrapFunctional(c.default || c))
export const BookingElementsRelatedDateOption = () => import('../../components/booking/elements/related/DateOption.vue' /* webpackChunkName: "components/booking-elements-related-date-option" */).then(c => wrapFunctional(c.default || c))
export const BookingElementsRelatedOptionSummary = () => import('../../components/booking/elements/related/OptionSummary.vue' /* webpackChunkName: "components/booking-elements-related-option-summary" */).then(c => wrapFunctional(c.default || c))
export const BookingElementsRelatedSingleHolidayOption = () => import('../../components/booking/elements/related/SingleHolidayOption.vue' /* webpackChunkName: "components/booking-elements-related-single-holiday-option" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
